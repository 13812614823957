export const CMD_PREFIX = ':ADM:';
export const CMD_SEPARATOR = '|';

export const CMD_START_CONFERENCE = 'START';
export const CMD_END_CONFERENCE   = 'FINISH';
export const CMD_ADD_VIDEO        = 'ADD_VIDEO';
export const CMD_CHECKIN_ACCEPT   = 'CHECKIN_ACCEPT';
export const CMD_CHECKIN_DENY     = 'CHECKIN_DENY';
export const CMD_UPDATE_STATUS    = 'UPDATE_STATUS';
export const CMD_SCREEN_BLOCKED   = 'SCREEN_BLOCKED';
export const CMD_REQUEST_UNBLOCK   = 'REQUEST_UNBLOCK';
export const CMD_UNBLOCK_SCREEN   = 'UNBLOCK_SCREEN'; 
export const CMD_IMG_OF_DOCUMENT  = 'IMG_OF_DOCUMENT';
export const CMD_WARNING_MSG      = 'WARNING_MSG';
export const CMD_WARNING_CLEAR    = 'WARNING_CLEAR';
export const CMD_CARD_YELLOW      = 'CARD_YELLOW';
export const CMD_CARD_RED         = 'CARD_RED';


export function getCommand ( cmd: string ): string {
  return  CMD_PREFIX+CMD_SEPARATOR+cmd; 
}
