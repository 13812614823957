import { Component, HostListener, OnDestroy } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import rainbowSDK from 'rainbow-web-sdk';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { AppGlobals } from './app.globals';
import { sdk } from './modules/sdk';
import { EventService } from './services/event.service';
import { AdminService } from './services/monitor-item-admin.service';

export enum FrameEvent {
  UPDATE_STATUS,
  REMOVE,
  BLOCK,
  UNBLOCK,
  KICK_OUT,
  GIVE_YELLOW_CARD,
  CHANGE_MAIN_VIDEO_PUBLISHER,
  CONFERENCE_STOPPED,
  CONFERENCE_STARTED,
  RESTART_CONFERENCE,
  TOGGLE_CHAT,
  TEXT_MESSAGE_RECEIVED,
  SEND_TEXT_MESSAGE,
  CLOSE_CHAT,
  ASK_TO_CHANGE_VIDEOS,
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy {
  title = 'PSU-MonitorItem';
  platform = 'rainbow';
  public _url = environment.urlMonitor;
  private conncount: number = 0;
  private isSdkLoaded = false;
  private subscription = new Subscription();

  @HostListener('window:message', ['$event']) onServerMessage(e) {
    if (e.origin != this._url) {
      return false;
    }

    if (!this.isSdkLoaded) {
      return false;
    }

    switch (e.data.event) {
      case FrameEvent.REMOVE:
        this.globals.currentStudent = null;
        rainbowSDK.connection.signout();
        break;
      case FrameEvent.UPDATE_STATUS:
        this.globals.currentStudent.status = e.data.newStatus;
        if (e.data.newStatus === '2') {
          this.eventService.emitStudentConnected();
        }
        break;
      case FrameEvent.BLOCK:
        this.globals.provaInterrompida = true;
        break;
      case FrameEvent.UNBLOCK:
        this.globals.provaInterrompida = false;
        break;
      case FrameEvent.KICK_OUT:
        this.eventService.emitKickout();
        break;
      case FrameEvent.GIVE_YELLOW_CARD:
        this.eventService.giveYellowCard();
        break;
      case FrameEvent.CHANGE_MAIN_VIDEO_PUBLISHER:
        this.eventService.changeMainVideoPublisher(e.data.primaryVideoContactId, e.data.secondaryVideoContactId);
        break;
      case FrameEvent.RESTART_CONFERENCE:
        this.eventService.restartConference();
        break;
      case FrameEvent.SEND_TEXT_MESSAGE:
        this.eventService.sendTextMessage(e.data.message);
        break;
      case FrameEvent.CLOSE_CHAT:
        this.eventService.closeChat();
        break;
      default:
        this.getMonitorInfo(e.data.fila_id);
        break;
    }

    if (e.data.configGravacao) {
      this.globals.configGravacao = e.data.configGravacao;
    }
  }

  private onLoaded = () => {
    this.isSdkLoaded = true;
    this.getVideoDeviceList();
  };

  constructor(
    public globals: AppGlobals,
    private _service: AdminService,
    private connectionStatusService: EventService,
    private eventService: EventService,
    private router: Router
  ) {
    document.addEventListener(rainbowSDK.RAINBOW_ONLOADED, this.onLoaded);
    sdk.initialize();

    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof ActivationStart) {
          if (event.snapshot.queryParams && event.snapshot.queryParams['interprete']) {
            this.globals.monitorInterprete = event.snapshot.queryParams['interprete'] === 'true';
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getMonitorInfo(idFila: string) {
    this._service.getMonitorInfo(idFila).subscribe(
      (val) => {
        if (!val.erro) {
          this.globals.currentStudent = val.message;
          this.connectToRainbow();
        } else {
          this.globals.currentStudent = null;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  private connectToRainbow() {
    if (!this.globals.currentStudent) {
      return;
    }

    if (rainbowSDK.connection.getState() === 'inProgress') {
      setTimeout(() => {
        this.doConnection();
      }, 2000);
    } else {
      this.doConnection();
    }
  }

  private async doConnection() {
    if (rainbowSDK.connection.getState() === 'connected') {
      this.globals.isConnected = true;
      this.connectionStatusService.emitConnectionSucess();
      return;
    }
    this.conncount++;
    try {
      let rainbowConnectionPromise;
      switch (this.platform) {
        case 'rainbow':
          rainbowConnectionPromise = rainbowSDK.connection.signinOnRainbowOfficial(
            this.globals.currentStudent.usuarioMonitor.email,
            this.globals.currentStudent.usuarioMonitor.password
          );
          break;
        default:
        case 'sandbox':
          rainbowConnectionPromise = rainbowSDK.connection.signin(
            this.globals.currentStudent.usuarioMonitor.email,
            this.globals.currentStudent.usuarioMonitor.password
          );
          break;
      }

      if (rainbowSDK.connection.getState() === 'inProgress') {
        setTimeout(() => {
          this.connectToRainbow();
        }, 2000);
      } else {
        await rainbowConnectionPromise
          .then((conn) => {})
          .catch((error) => {
            this.globals.isConnected = false;
          });
        if (!this.globals.isConnected) {
          this.connectToRainbow();
        }
      }
    } catch (e) {
      if (this.conncount <= 3) {
        this.connectToRainbow();
      }
    }
  }

  private getVideoDeviceList() {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          if (device.kind == 'videoinput') {
            this.globals.videoDevices.push(device);
          }
        });

        if (this.globals.videoDevices.length > 0) {
          this.globals.videoDeviceId = this.globals.videoDevices[0].deviceId;
        }
      })
      .catch(function (err) {});
  }
}
