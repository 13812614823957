<div class="conference-container">
  <audio
    id="globalAudioTag"
    #globalAudioTag
    autoplay
    style="display: none"
  ></audio>
  <video
    id="globalVideoTag"
    #globalVideoTag
    autoplay
    style="display: none"
  ></video>

  <video
    *ngIf="globals.monitorInterprete"
    id="conferenceVideoPip"
    #conferenceVideoPip
    autoplay
    muted
    width="445px"
    class="local-video"
  ></video>

  <video id="largevideo" autoplay class="primary-video"></video>

  <video
    id="secondaryVideo"
    #secondaryVideo
    autoplay
    class="secondary-video"
  ></video>

  <div
    *ngIf="globals.inConference && globals.currentStudent?.status === '3'"
    class="chat-toggle-button"
    [ngClass]="{ 'chat-toggle-button-opened': chatOpened }"
    (click)="toggleChat()"
  >
    <i class="fa fa-comments"></i>
    <span *ngIf="unreadChatMessages" class="unread-messages-count">{{
      unreadChatMessages
    }}</span>
  </div>

  <div class="title" *ngIf="globals.currentStudent">
    <span style="margin-top: 4px; width: 100%">
      {{ globals.currentStudent.alunoGioconda.aluno_nome }} ({{
        globals.currentStudent.alunoGioconda.aluno_codigo
      }})
    </span>
    <div class="blink" *ngIf="globals.currentStudent && messageWarning.length">
      <span style="margin-top: -60px; width: 100%; display: block">
        {{ messageWarning }}
      </span>
    </div>
  </div>
  <div
    [ngClass]="{
      status: !globals.provaInterrompida,
      blink: globals.provaInterrompida
    }"
    class="status"
    *ngIf="globals.currentStudent"
  >
    <span class="widget-title">
      {{ studentStatus }}
    </span>
    <div
      class="widget-title"
      *ngIf="globals.currentStudent.prova_teste === 'S'"
    >
      (Teste)
    </div>
  </div>
  <div class="side-bar" *ngIf="globals.inConference">
    <div
      class="fa fa-exchange btn-troca-cameras"
      (click)="askToChangeVideos()"
      tooltipPosition="right"
      pTooltip="Trocar câmeras"
    ></div>
    <div
      class="fa btnmicrophone"
      [ngClass]="{ 'fa-microphone-slash': isMute, 'fa-microphone': !isMute }"
      (click)="onMuteUnmute()"
      tooltipPosition="right"
      [pTooltip]="isMute ? 'Ligar microfone' : 'Desligar microfone'"
    ></div>

    <div
      class="fa btnmicrophone"
      [ngClass]="{ 'fa-volume-off': volume == 0, 'fa-volume-up': volume == 1 }"
      (click)="onVolumeMuteUnmute()"
      tooltipPosition="right"
      [pTooltip]="volume == 0 ? 'Ligar áudio' : 'Desligar áudio'"
    ></div>

    <div
      class="fa btnmicrophone"
      [ngClass]="{ 'fa-stop-circle': gravando, 'fa-circle': !gravando }"
      (click)="gravando ? onPararGravacaoClick() : onGravarClick()"
      tooltipPosition="right"
      [pTooltip]="gravando ? 'Parar gravação' : 'Gravar'"
    ></div>
  </div>
</div>
