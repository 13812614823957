import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AppGlobals {
  public readonly API_HOST = environment.apiHost;
  private readonly appVersion = '0.1.1';
  private readonly restPrefix = `${this.API_HOST}/integra-rainbow-api`;

  public getAppVersion = () => this.appVersion;
  public getRestPrefix = () => this.restPrefix;

  public isConnected: boolean = false;
  public currentStudent: any;

  public advertencias: number = 0;
  public provaEmAndamento: boolean = false;
  public provaInterrompida: Boolean = false;
  public inConference: boolean = false;
  public hasConferenceToJoin: boolean = false;
  public defaultUser: string = '';
  public bubbleCalling: any;
  public configGravacao: any;

  public idUserGioconda: any;

  public videoDeviceId: any;
  public videoDevices: any = [];
  public monitorInterprete = false;
}
