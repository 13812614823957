import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppGlobals } from 'src/app/app.globals';

@Injectable({ providedIn: 'root' })
export class AdminService {
  private readonly _restPrefix: string;

  constructor(private globals: AppGlobals, private http: HttpClient) {
    this._restPrefix = this.globals.getRestPrefix();
  }

  public getMonitorInfo(idFila: string): Observable<any> {
    const apiURL = this._restPrefix + '/getMonitorInfo';
    const body = { fila_id: idFila };
    return this._requestData(apiURL, 'POST', body).pipe(take(1));
  }

  public updateAlunoStatus(idFila: string, idStatus: number): Observable<any> {
    const apiURL = this._restPrefix + '/updateStatusFila';
    const body = { fila_id: idFila, status: idStatus };
    return this._requestData(apiURL, 'POST', body);
  }

  public addArquivoGravacao(
    idFila,
    nome,
    mime,
    size,
    type,
    metodo,
    descId,
    fileData
  ): Observable<any> {
    const apiURL = this._restPrefix + '/addArquivoGravacao';
    const body = {
      id_fila: idFila,
      mime: mime,
      nome: nome,
      size: size,
      arquivo: fileData,
      tipo: type,
      descricao: descId,
      metodo: metodo,
    };
    return this._requestData(apiURL, 'POST', body);
  }

  private _requestData(
    apiURL: string,
    method: 'GET' | 'POST' | 'UPDATE' | 'DELETE' | 'PATCH' = 'GET',
    body: any = {}
  ): Observable<any> {
    let headers_object = new HttpHeaders();
    headers_object = headers_object.append('Content-Type', 'application/json');
    headers_object = headers_object.append(
      'Authorization',
      'Basic ' + btoa('admin:superuser')
    );
    headers_object = headers_object.append('Accept', '*/*');

    const httpOptions = {
      headers: headers_object,
    };

    switch (method) {
      case 'GET':
        return this.http.get(apiURL, httpOptions);
      case 'POST':
        return this.http.post(apiURL, body, httpOptions);
      default:
        return null;
    }
  }
}
