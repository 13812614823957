import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import rainbowSDK from 'rainbow-web-sdk';
import MultiStreamRecorder from 'recordrtc/RecordRTC.min';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FrameEvent } from '../app.component';
import { AppGlobals } from '../app.globals';
import { CMD_END_CONFERENCE, CMD_WARNING_CLEAR, CMD_WARNING_MSG } from '../classes/app.consts';
import { EventService } from '../services/event.service';
import { AdminService } from '../services/monitor-item-admin.service';

export interface ChatMessage {
  isInOrOut?: boolean;
  from: string;
  text: string;
}

@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.css'],
})
export class ConferenceComponent implements OnInit, OnDestroy {
  @ViewChild('globalAudioTag') audioTag: ElementRef<HTMLAudioElement>;

  public gravando: any = false;
  public isMute: boolean = false;
  public volume: number = 1;
  public conferenceSession: any;
  public conversation: any;
  public messageWarning: any = '';
  public remoteMidias: any[];

  private primaryVideoContactId: string;
  private secondaryVideoContactId: string;

  public chatMessages: ChatMessage[] = [];
  public chatOpened = false;
  public unreadChatMessages = 0;

  private tryingToStartConf: boolean = false;
  private recordRTC: any;
  private timeGetMessages = 2000;
  private lastProcessedMessage = 0;
  private isOpeningBubble = false;
  private subscription = new Subscription();
  private chatNotificationAudio = new Audio('../../assets/audio/chat-notification.wav');

  private onConversationsListChanged = (event) => {
    const conv = event.detail;
    if (this.globals.currentStudent) {
      this.conversation = conv;
      this.processPSUMessages();
    }
  };

  private onConnectionStateChangeEvent = (event) => {
    if (rainbowSDK.connection.getState() === rainbowSDK.connection.RAINBOW_CONNECTIONCONNECTED) {
      if (!this.isOpeningBubble) {
        this.isOpeningBubble = true;
      }
    } else {
      this.initializeVariables();
      this.setStatus();
    }
  };

  private onBubbleUpdate = (event) => {
    const bubble = event.detail;
    if (!bubble) return;

    
    if (
      this.globals.currentStudent &&
      bubble.dbId === this.globals.currentStudent.bolha.id &&
      rainbowSDK.webConference.hasActiveWebConferenceSession()
    ) {
      this.globals.bubbleCalling = bubble;
      this.globals.hasConferenceToJoin = (!rainbowSDK.webConference.hasActiveWebConferenceSession());  
    } else if (!this.globals.currentStudent || bubble.dbId === this.globals.currentStudent.bolha.id) {
      this.globals.hasConferenceToJoin = false;
    }
    
    this.globals.inConference = rainbowSDK.webConference.hasActiveWebConferenceSession();

    if (!this.globals.inConference && this.globals.hasConferenceToJoin) {
      this.loadConversation();
    }

    this.setStatus();
  };

  private onBubbleConferenceStartReceived = (event) => {
    this.globals.bubbleCalling = event.detail;
  };

  private onConferenceUpdated = (event) => {
    let session = rainbowSDK.webConference.getActiveWebConferenceSession();

    if (session?.roomId === this.globals.currentStudent.bolha.id) {
      this.conferenceSession = session;
      if (this.globals.inConference ) {
        // window.parent.postMessage({fila_id: this.globals.currentStudent.fila_id, event: FrameEvent.CONFERENCE_STOPPED}, environment.urlMonitor);
      } else if (!this.globals.inConference) {
        window.parent.postMessage(
          { fila_id: this.globals.currentStudent.fila_id, event: FrameEvent.CONFERENCE_STARTED },
          environment.urlMonitor
        );
      }
      this.globals.inConference = rainbowSDK.webConference.hasActiveWebConferenceSession();
      this.getRemoteMidias();
      if (this.globals.monitorInterprete) {
        this.showLocalVideo();
      }
    }
  };

  private processVideo = (audioVideoWebMURL) => {
    this.recordRTC.getDataURL((dataURL) => {
      this.addArquivoGravacao(
        'Monitor: Suspeição',
        this.globals.currentStudent.alunoGioconda.aluno_nome + ' - Suspeição',
        dataURL,
        1
      );
    });
  };

  private onNewTextMessageReceived = (event) => {
    const { message, conversation } = event.detail;
    if (message.data && this.globals.inConference && this.globals.currentStudent?.status === '3' && !this.isInOrOutTextMessage(message.data)) {
      if (message.from.name.value.toUpperCase().includes('CHECKIN')) {
        window.parent.postMessage(
          {
            event: FrameEvent.TEXT_MESSAGE_RECEIVED,
            fila_id: this.globals.currentStudent.fila_id,
            message: { from: 'Aprovador', text: message.data },
          },
          environment.urlMonitor
        );
        if (!this.chatOpened) {
          this.addUnreadMessage();
        }
      } else if (message.from.name.value.toUpperCase().includes('ALUNO')) {
        window.parent.postMessage(
          {
            event: FrameEvent.TEXT_MESSAGE_RECEIVED,
            fila_id: this.globals.currentStudent.fila_id,
            message: { from: this.globals.currentStudent.alunoGioconda.aluno_nome, text: message.data },
          },
          environment.urlMonitor
        );
        if (!this.chatOpened) {
          this.addUnreadMessage();
        }
      }
    }
  };

  constructor(public globals: AppGlobals, private _service: AdminService, private eventService: EventService) {
    this.subscription.add(
      eventService.connectionStatus$.subscribe((status) => {
        if (status === 'connectionSucess') {
          this.loadBubble().then(() => {
            this.loadConversation();
          });
        }
      })
    );

    this.subscription.add(
      eventService.kickout$.subscribe(() => {
        this.kickoutStudent();
      })
    );

    this.subscription.add(
      eventService.giveYellowCard$.subscribe(() => {
        this.onBtnAdvertirClick();
      })
    );

    this.subscription.add(
      eventService.changeMainVideoPublisher$
        .pipe(delay(1500))
        .subscribe(({ primaryVideoContactId, secondaryVideoContactId }) => {
          this.updateMainVideoSession(primaryVideoContactId, secondaryVideoContactId);
        })
    );

    this.subscription.add(
      eventService.restartConference$.subscribe(() => {
        this.onStartConf();
      })
    );

    this.subscription.add(
      eventService.sendTextMessage$.subscribe((message) => {
        this.sendTextMessage(message);
      })
    );

    this.subscription.add(
      eventService.closeChat$.subscribe((message) => {
        this.chatOpened = false;
      })
    );

    this.subscription.add(
      eventService.studentConnected$.subscribe(() => {
        if (this.globals.currentStudent.alunoGioconda.deficiencia) {
          this.addLocalVideo();
        }
      })
    );
  }

  ngOnInit() {
    document.addEventListener(rainbowSDK.conversations.RAINBOW_ONCONVERSATIONSCHANGED, this.onConversationsListChanged);
    document.addEventListener(
      rainbowSDK.connection.RAINBOW_ONCONNECTIONSTATECHANGED,
      this.onConnectionStateChangeEvent
    );
    document.addEventListener(rainbowSDK.bubbles.RAINBOW_ONBUBBLEUPDATED, this.onBubbleUpdate);
    document.addEventListener(
      rainbowSDK.bubbles.RAINBOW_ONBUBBLECONFERENCESTARTEDINVITATIONRECEIVED,
      this.onBubbleConferenceStartReceived
    );
    
    document.addEventListener(rainbowSDK.im.RAINBOW_ONNEWIMMESSAGERECEIVED, this.onNewTextMessageReceived);
    this.chatNotificationAudio.volume = 0.2;

    rainbowSDK.webConference.subscribe((event: any) => {
      try {
          if (event.name === rainbowSDK.webConference.RAINBOW_ONWEBCONFERENCESTARTEDEVENT) {
           //  console.log('conf:*** start event', event);
          }
          else if (event.name === rainbowSDK.webConference.RAINBOW_ONWEBCONFERENCEPARTICIPANTLISTUPDATED) {
             // console.log('conf:*** list of participants has updated');
              
          }
          else if (event.name === rainbowSDK.webConference.RAINBOW_ONWEBCONFERENCESTATUSUPDATED) {
            //console.log('conf:*** status updated', event);
            this.onConferenceUpdated(event);
          }
          else if (event.name === rainbowSDK.webConference.RAINBOW_ONWEBCONFERENCEREMOTEMEDIAUPDATE) {
            //console.log('conf:*** remote video / sharing started or stopped')
            this.showLocalVideo();
            this.showRemoteVideo();
          }
          else if (event.name === rainbowSDK.webConference.RAINBOW_ONWEBCONFERENCEPUBLISHERSREMOVED) {
            //console.log('conf:*** someone stopped his video')
          }
          else if (event.name === rainbowSDK.webConference.RAINBOW_ONWEBCONFERENCELOCALMEDIAUPDATE) {
            //console.log('conf:*** update on the local media')
            this.showLocalVideo();
              //manage mute or local video / sharing
            //show local mídia

          }
          else if (event.name === rainbowSDK.webConference.RAINBOW_ONWEBCONFERENCEREPARTICIPANTAUPDATE) {
            //console.log('conf:*** update on some other participant (muted, or sth else)')
          }
          else if (event.name === rainbowSDK.webConference.RAINBOW_ONWEBCONFERENCERETALKERAUPDATE) {
            //console.log('conf:*** update on talker')
              
          }
          else {
            //console.log('conf:*** Evento: ',event.name)
          }
          //other events
      }
      catch (_err) {
        console.log('*** ERRO', _err)
      }
    })


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public askToChangeVideos() {
    window.parent.postMessage(
      { event: FrameEvent.ASK_TO_CHANGE_VIDEOS, fila_id: this.globals.currentStudent.fila_id },
      environment.urlMonitor
    );
  }

  public async kickoutStudent() {
    if (this.globals.currentStudent) {
      this.onPararGravacaoClick();

      const filaId = this.globals.currentStudent.fila_id;
      this._service.updateAlunoStatus(filaId, 7).subscribe(() => {});
      if (this.globals.inConference) {
        await rainbowSDK.webConference.leaveWebConference(rainbowSDK.webConference.getActiveWebConferenceSession());
      }
      this.globals.currentStudent = null;
      this.setStatus();
    }
  }

  public onBtnAdvertirClick() {
    this.globals.advertencias++;

    if (this.globals.configGravacao.finaliza_apos_cartao) {
      this.onPararGravacaoClick();
    }

    if (this.globals.advertencias >= 3) {
      this.kickoutStudent();
    }
  }

  public onMuteUnmute() {
    if (!this.conferenceSession ) {
      this.conferenceSession = this.getConferenceObject(this.globals.bubbleCalling);
    }

    if (this.isMute) {
      this.unmuteMicrophone();
    } else {
      this.muteMicrophone();
    }
  }

  public onVolumeMuteUnmute() {
    const audio = this.audioTag.nativeElement;

    if (this.volume == 1) {
      this.volume = 0;
    } else {
      this.volume = 1;
    }

    if (audio) {
      audio.volume = this.volume;
    }
  }

  public onGravarClick() {
    const streams = [];
    Object.keys(this.conferenceSession.sessions).forEach((vg) => {
      const session = this.conferenceSession.sessions[vg];
      streams.push(...session.remoteStreams);
      streams.push(...session.localStreams);
    });

    if (streams.length > 0) {
      this.gravando = true;
      this.startRecordOfAllStreams(streams);
      if (this.globals.configGravacao && this.globals.configGravacao.tempo_maximo > 0) {
        setTimeout(() => {
          this.onPararGravacaoClick();
        }, this.globals.configGravacao.tempo_maximo * 1000);
      }
    }
  }

  public onPararGravacaoClick() {
    const recordRTC = this.recordRTC;
    if (this.gravando) {
      this.gravando = false;
      recordRTC.stopRecording(this.processVideo);
    }
  }

  public toggleChat() {
    this.chatOpened = !this.chatOpened;
    window.parent.postMessage(
      { event: FrameEvent.TOGGLE_CHAT, fila_id: this.globals.currentStudent.fila_id },
      environment.urlMonitor
    );
    this.unreadChatMessages = 0;
  }

  private sendTextMessage(chatMessageText: string) {
    if (chatMessageText?.trim()) {
      rainbowSDK.im.sendMessageToConversation(this.conversation.id, chatMessageText);
    }
  }

  private addUnreadMessage() {
    this.unreadChatMessages++;
    this.chatNotificationAudio.play();
  }

  private isInOrOutTextMessage(textMessage: string) {
    return ['conferenceAddMsgRoom', 'conferenceRemoveMsgRoom', 'joinMsgRoom'].includes(textMessage);
  }

  private translateInOrOutTextMessage(textMessage: string) {
    switch (textMessage) {
      case 'conferenceAddMsgRoom':
      case 'joinMsgRoom':
        return 'Entrou na conferência';
      case 'conferenceRemoveMsgRoom':
        return 'Saiu da conferência';
      default:
        return '';
    }
  }

  private updateMainVideoSession(primaryVideoContactId, secondaryVideoContactId) {

    this.primaryVideoContactId = primaryVideoContactId; 
    this.secondaryVideoContactId = secondaryVideoContactId;
    
    this.showRemoteVideo();

  }

  private showLocalVideo() {
      
    this.conferenceSession = rainbowSDK.webConference.getActiveWebConferenceSession();
    
    if (this.conferenceSession) {
      if (this.conferenceSession.localParticipant.videoSession)
        rainbowSDK.webConference.attachVideoSessionToHtmlElement("conferenceVideoPip" , this.conferenceSession.localParticipant.videoSession);
    }

  }

  private initializeVariables() {
    this.globals.hasConferenceToJoin = false;
    this.conferenceSession = null;
    this.conversation = null;
    this.messageWarning = null;
    this.remoteMidias = [];
  }

  private setStatus() {
    if (!this.globals.hasConferenceToJoin) {
      this.remoteMidias = [];
      this.globals.inConference = false;
      this.globals.provaEmAndamento = false;
      this.globals.provaInterrompida = false;
      this.globals.advertencias = 0;
      this.globals.bubbleCalling = null;
      this.messageWarning = '';
    }
  }

  public onStartConf() {
    if (this.tryingToStartConf) {
      return;
    }

    this.tryingToStartConf = true;
    // this.triedToStartConf = true;
    rainbowSDK.config.setSimulcast(false);
    rainbowSDK.config.setVideoQuality('low');
    rainbowSDK.config.setVerboseLog(false);
    
    rainbowSDK.webConference.joinWebConference(this.globals.bubbleCalling)
      .then(async (bubble) => {
        this.tryingToStartConf = false;
        this.globals.bubbleCalling = bubble;
        this.conferenceSession = rainbowSDK.webConference.getActiveWebConferenceSession();

        this.conversation = await rainbowSDK.conversations.getConversationByBubbleId(
          this.globals.currentStudent?.bolha.id
        );
        this.onMuteUnmute();
        this.onVolumeMuteUnmute();
        if (this.globals.currentStudent.status != 1 && this.globals.currentStudent.alunoGioconda.deficiencia) {
          this.addLocalVideo();
        }
      })
      .catch((error) => {
        this.tryingToStartConf = false;
        // this.triedToStartConf = true;
        setTimeout(() => {
          if (!this.globals.inConference) {
            this.onStartConf();
          }
        }, 3000);
      });
  }

  private async processCommand(command, orgMsg) {
    switch (command[1]) {
      case CMD_END_CONFERENCE:
        if (this.globals.inConference) {
          await rainbowSDK.webConference.leaveWebConference(rainbowSDK.webConference.getActiveWebConferenceSession());
        }
        break;
      case CMD_WARNING_MSG:
        this.messageWarning = command[2];
        console.log(this.messageWarning);
        break;
      case CMD_WARNING_CLEAR:
        this.messageWarning = '';
        break;
    }
  }

  private processPSUMessages() {
    setTimeout(() => {
      if (this.conversation) {
        if (this.timeGetMessages === 2000) this.lastProcessedMessage = this.conversation.messages.length;
        for (
          this.lastProcessedMessage;
          this.lastProcessedMessage < this.conversation.messages.length;
          this.lastProcessedMessage++
        ) {
          if (this.conversation.messages[this.lastProcessedMessage].side === 'L') {
            let command = this.conversation.messages[this.lastProcessedMessage].data.split('|');
            if (command.length >= 2 && command[0] === ':ADM:')
              this.processCommand(command, this.conversation.messages[this.lastProcessedMessage]);
          }
        }
        this.timeGetMessages = 200;
      }
    }, this.timeGetMessages);
  }

  loadBubble() {
    return new Promise((resolve) => {
      this.globals.advertencias = 0;
      this.globals.bubbleCalling = null;
      this.remoteMidias = [];

      const bubbles = rainbowSDK.bubbles.getAllPendingBubbles();

      bubbles.forEach((bubble) => {
        if (bubble.dbId === this.globals.currentStudent?.bolha.id) {
          rainbowSDK.bubbles.acceptInvitationToJoinBubble(bubble);
          this.timeGetMessages = 2000;
          this.lastProcessedMessage = 0;
        }
      });
      resolve(true);
    });
  }

  loadConversation() {
    this.globals.bubbleCalling = rainbowSDK.bubbles.getBubbleById(this.globals.currentStudent?.bolha.id);
    if (this.globals.bubbleCalling && !rainbowSDK.webConference.hasActiveWebConferenceSession()) {
      rainbowSDK.conversations.getConversationByBubbleId(this.globals.currentStudent?.bolha.id);
      this.isOpeningBubble = false;
      this.onStartConf();
    }
  }

  public isVideoPrimarioDoAluno(videoGalleryItem: any) {
    return videoGalleryItem.displayName.startsWith('Aluno ');
  }

  private getConferenceObject(bubble) {

    if (!rainbowSDK.webConference.hasActiveWebConferenceSession()) {
      return null;
    }
    return rainbowSDK.webConference.getActiveWebConferenceSession();
  }

  private getRemoteMidias() {

    this.conferenceSession = rainbowSDK.webConference.getActiveWebConferenceSession();

    if (this.conferenceSession) {
      const participants = this.conferenceSession.getParticipants();
      let videos = [];
      for (let participant of participants) {
        if (participant.contact.id != this.conferenceSession.localParticipant.id
           && participant.contact.fullname != 'Usuário Checkin'
           && participant.videoSession) {
            videos.push(participant.videoSession )
           }
      }
      
      if (this.remoteMidias?.length !== videos.length) {
        this.remoteMidias = videos;
          this.showLocalVideo();
          setTimeout(() => {
            this.showRemoteVideo();
          }, 1000);
  
        }
      }
  }


  showRemoteVideo(){
    this.conferenceSession = rainbowSDK.webConference.getActiveWebConferenceSession();


      if (this.conferenceSession) {
        this.globals.inConference = true;
        const participants = this.conferenceSession.getParticipants();
        let streams = [];
        for (let participant of participants)
        {
          
          if (this.primaryVideoContactId) {
            if (participant.contact.id == this.primaryVideoContactId  && participant.videoSession){
              rainbowSDK.webConference.attachVideoSessionToHtmlElement("largevideo" , participant.videoSession);             
            }
  
            if (participant.contact.id == this.secondaryVideoContactId  && participant.videoSession){
              rainbowSDK.webConference.attachVideoSessionToHtmlElement("secondaryVideo" , participant.videoSession);             
            }
          }
          else 
          {
            if (participant.contact.id != this.conferenceSession.localParticipant.id 
                 && participant.contact.name.value != 'Usuário Checkin'
                 && participant.videoSession){
              rainbowSDK.webConference.attachVideoSessionToHtmlElement("largevideo" , participant.videoSession);
            }
  
            if (participant.contact.name.value == 'Câmera Secundária' && participant.videoSession){
              
              rainbowSDK.webConference.attachVideoSessionToHtmlElement("secondaryVideo" , participant.videoSession);             
            }
          }
        }
      }

  }

  get studentStatus(): string {
    if (this.globals.provaInterrompida) {
      return 'PROVA INTERROMPIDA';
    }
    switch (this.globals.currentStudent?.status) {
      case '1':
        return 'AGUARDANDO';
      case '2':
        return 'CHECKIN';
      case '3':
        return 'REALIZANDO PROVA';
      case '4':
        return 'FINALIZADO';
      case '5':
        return 'CANCELADO';
      case '6':
        return 'DESQUALIFICADO';
      case '7':
        return 'PENALIZADO';
      default:
        return '';
    }
  }

  private muteMicrophone() {
    rainbowSDK.webConference.updateParticipant(this.conferenceSession, this.conferenceSession.localParticipant.id, "mute").then(() => {
      this.isMute = true;
    });
  }

  private unmuteMicrophone() {
    rainbowSDK.webConference.updateParticipant(this.conferenceSession, this.conferenceSession.localParticipant.id, "unmute").then(() => {
      this.isMute = false;
    });
  }

  private startRecordOfAllStreams(streams) {
    this.recordRTC = new MultiStreamRecorder(streams, {
      mimeType: 'video/webm',
    });
    this.recordRTC.startRecording();
  }

  private addArquivoGravacao(motivo, nome, data, type) {
    const dataMime = data.split(';')[0];
    const mime = dataMime.split(':')[1];
    const size = data.length;
    const descId = 2;

    this._service
      .addArquivoGravacao(this.globals.currentStudent.fila_id, nome, mime, size, type, 1, descId, data)
      .subscribe((res) => {});
  }

  private addLocalVideo() {
    try {
      if (this.globals.videoDeviceId) {
        if (this.isChrome()) {
          if (this.globals.videoDeviceId) {
            let dvcList = rainbowSDK.deviceManagement.getAllVideoInputDevices();
            let dvc = dvcList.filter( (e)=> e.deviceId == this.globals.videoDeviceId);
            if (dvc.length>0)
               rainbowSDK.deviceManagement.setCurrentVideoInputDevice(dvc[0])
          }

        }
        rainbowSDK.webConference.addMediaToWebConferenceSession(this.conferenceSession, 'video' );

      }
    } catch (e) {
      console.error(`add video error: ${e}`);
    }
  }

  private isChrome() {
    return navigator.userAgent.indexOf('Chrome') > -1;
  }
}
