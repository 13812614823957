import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventService {
  public connectionStatus$ = new Subject();
  public kickout$ = new Subject();
  public studentConnected$ = new Subject();
  public giveYellowCard$ = new Subject();
  public changeMainVideoPublisher$ = new Subject();
  public restartConference$ = new Subject();
  public sendTextMessage$ = new Subject<string>();
  public closeChat$ = new Subject();

  emitConnectionSucess() {
    this.connectionStatus$.next('connectionSucess');
  }
  emitKickout() {
    this.kickout$.next();
  }
  emitStudentConnected() {
    this.studentConnected$.next();
  }
  giveYellowCard() {
    this.giveYellowCard$.next();
  }
  changeMainVideoPublisher(primaryVideoContactId: string, secondaryVideoContactId: string) {
    this.changeMainVideoPublisher$.next({ primaryVideoContactId, secondaryVideoContactId });
  }
  restartConference() {
    this.restartConference$.next();
  }
  sendTextMessage(textMessage: string) {
    this.sendTextMessage$.next(textMessage);
  }
  closeChat() {
    this.closeChat$.next();
  }
}
