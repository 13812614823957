import rainbowSDK from 'rainbow-web-sdk';

//declare var rainbowSDK: any;
declare var angular: any;
declare var $: any;

/**
 * SDK
 * TypeScript Singleton
 * Centralize access to rainbowSDK global variable (avoid dispatching rainbowSDK anywhere in the application)
 */
class SDK {
  initialize() {
    const applicationID = '41b63250d02c11ebb07425312b3be88c';
    const applicationSecret = 'zuyV0ZoYESfb97WdMRFtTIV90pdHvcZgpfSXrrOTuDLeIMrsARZ1ybdUuuVHLTqL';

    angular.bootstrap(document, ['sdk']).get('rainbowSDK');

    const onReady = function onReady() {};

    const onLoaded = function onLoaded() {
      rainbowSDK
        .initialize(applicationID, applicationSecret)
        .then(function () {})
        .catch(function () {});
    };

    $(document).on(rainbowSDK.RAINBOW_ONREADY, onReady);
    $(document).on(rainbowSDK.RAINBOW_ONLOADED, onLoaded);

    rainbowSDK.start();
    rainbowSDK.load({});
  }

  get version() {
    return rainbowSDK.version;
  }
}

const sdk = new SDK();

export { sdk };
